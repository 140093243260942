import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import { Link } from 'gatsby'
import Header from '../../components/header'
import CharacterGrid from '../../components/charactergrid'
import InBrief from '../../components/inbrief'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageRight, ImageFull, ImageCenter } from '../../components/imageelements'
import { SynopsisAct } from '../../components/synposisstyling'
import Bibliography from '../../components/bibliography.js'
import { Helmet } from "react-helmet"
import AudioSet from '../../components/audioset'
import { AdContainer } from '../../components/adstyling'
import { FunFactsHeader } from '../../components/funfactsstyling'
import { MainBodySection } from '../../components/generalstyling'
import VideoPlayer from '../../components/videoplayer'

const characters = [
  {name: 'Cio-Cio San', voice: 'Soprano', desc: 'The title role. A naive, 15-year-old Japanese girl.'},
  {name: 'Suzuki', voice: 'Mezzo-soprano', desc: 'Cio-Cio San’s maid. Fiercely loyal to Butterfly and vastly more aware of the tragedy unfolding.'},
  {name: 'Benjamin Pinkerton', voice: 'Tenor', desc: 'A Lieutenant in the U.S. Navy. A morally dubious type who marries Cio-Cio San on what he considers a short term lease.'},
  {name: 'Sharpless', voice: 'Baritone', desc: 'The U.S. Consul to Nagasaki. A kindly man and the voice of reason to Pinkerton but sadly ineffectual.'},
  {name: 'Goro', voice: 'Tenor', desc: 'Nominally a match-maker but apparently a real estate agent as well. Rather a slimy chap with little sympathy or care for Cio-Cio San.'},
  {name: 'Prince Yamadori', voice: 'Tenor', desc: ' A wealthy chap whom Cio-Cio San rather rudely dismisses.'},
  {name: 'The Bonze', voice: 'Bass', desc: 'Cio-Cio San’s Uncle. A small role, he briefly appears to chastise Cio-Cio San for abandoning her religion in converting to Pinkerton’s Christianity.'},
  {name: 'Kate Pinkerton', voice: 'Mezzo-soprano', desc: 'Pinkerton’s new American wife. Only pitches up briefly in the second half, likeable, but you’re unlikely to like her anyway.'},
  {name: 'Sorrow', voice: 'Silent', desc: 'Pinkerton and Butterfly’s son.'},
  ];

const inbrief = {name: 'Madama Butterfly', 'translatedname': 'Madam Butterfly', composer: "Giacomo Puccini", librettist: "Luigi Illica and Giuseppe Giacosa", language: "Italian", date: 'February 17th, 1904', acts: "Three", musiclength: "Two hours, twenty minutes"};

const bibliography = [
  {name: 'Madame Butterfly', writer: 'John Luther Long', url: 'https://amzn.to/3ycu0wV'},
  {name: 'A Night at the Opera: An Irreverent Guide to The Plots, The Singers, The Composers, The Recordings', writer: 'Sir Denis Forman', url: 'https://amzn.to/3syZYBX'},
  {name: 'Madam Butterfly: A guide to the opera', writer: 'Mosco Carner', url: 'https://amzn.to/3D0ihoG'},
  {name: 'The Contrasting Styles of ‘Madama Butterfly’ & Its Revival As ‘Miss Saigon’', writer: 'James Monroe Števko', url: 'https://operawire.com/opera-vs-broadway-the-constrasting-styles-of-madama-butterfly-its-revival-as-miss-saigon/'},
]

// markup
const ButterflyPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Madama Butterfly | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operas/butterfly" />
          <meta name="description" content="A guide to Giacomo Puccini's endlessly moving Madama Butterfly, including Synopsis, Music & Arias, Fun Facts, Running Time and much more!" />
        </Helmet>
        <Hero title="Madama Butterfly" subtitle="An Opera by Giacomo Puccini"/>
        <MainBodySection>

          <p><em>Madama Butterfly</em> is Puccini at his most restrained. A single setting, a relatively simple plot, and a strongly drawn lead pair around whom the narrative spins. There are a fair number of smaller roles, but don’t worry about keeping track of them, especially all of Butterfly’s relatives - even Puccini unnamed some of them while revising the story. There is also minimal chorus action, so if you are familiar with other Puccini operas, such as <em><Link to="/operas/turandot">Turandot</Link></em>, don’t go in expecting such spectacle, you may be disappointed.</p>

          <ImageFull>
            <StaticImage src="../../images/operas/butterfly/ROHButterflyDeathBanner.jpg" alt="Final Scene: Royal Opera Madama Butterfly"/>
            <figcaption>Madama Butterfly at the Royal Opera House</figcaption>
          </ImageFull>

          
          <div id="ezoic-pub-ad-placeholder-102"> </div>

          <p>That said, it’s a highly accessible and listenable opera, much more so than <em>Turandot</em> on the first go, and there are plenty of top draw tunes to be heard. At least in fame terms, the pick of the bunch is “Un Bel Di Vedremo,” which means “One good day, we will see.” This aria gets wheeled out by many a soprano for recital purposes, but in context, right at the start of Act II, it’s as sad as it is beautiful. The tenor’s music is somewhat less subtle and, in fact, rather potently blunt. The first big number of the evening is the thrilling tune “Dovunque al mondo,” or “Throughout the world.” It is built on top of “The Star-Spangled Banner”, which you should be able to pick out fairly easily in the clip below.</p>

          <AudioSet audiosrc="/audio/butterfly/Dovunque.mp3" desc="The opening passage of Dovunque al mondo"/>

          <p>Other highlights include the endings of all three acts, which comprise an extended love duet, a beautiful chorus, and an operatic staple, the suicide aria.</p>

          <Header title="In Brief"/>

          <InBrief content={inbrief}/>

          </MainBodySection>

  
          <div id="ezoic-pub-ad-placeholder-103"> </div>


            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Characters"/>

            <CharacterGrid rows={characters}/>

            <Header title="Synopsis"/>

            <SynopsisAct>Act I - Running Time: 55 mins</SynopsisAct>

            <p>The curtain rises on Lieutenant Pinkerton and Goro inspecting the house that Pinkerton has leased in Nagasaki while he is stationed in Japan. Goro shows him around, there’s much fuss made over the sliding walls, and introduces him to the household staff, including Suzuki, Butterfly’s maid.</p>

            <p>Sharpless, the U.S. Consul, arrives, all out of breath from climbing the hill to the house.
                They discuss Pinkerton’s situation, and the topic of Butterfly soon arises. Sharpless warns Pinkerton that Butterfly is taking the marriage very seriously, but Pinkerton dismisses these concerns with a good whack of American bravura and whiskey (in the roistering good tune “Dovunque al mondo”). Despite his Butterfly infatuation, he is already thinking of his future American bride. The scene is set for our tragedy!</p>

            <VideoPlayer src="https://www.youtube.com/embed/wB8xg4v7oyI?autoplay=1" id="wB8xg4v7oyI" title="Lando Bartolini sings Dovunque al mondo"/>

            <p>We hear Madam Butterfly for the first time. She is coming up the hill full of joy for her impending wedding. She arrives with her friends and approaches Pinkerton. In the ensuing dialogue, we learn that Butterfly comes from a wealthy family but now makes a living working as a Geisha, which it should be pointed out doesn’t mean prostitute.</p>

            <ImageRight>
              <StaticImage src="../../images/operas/butterfly/Butterfly_Arriving_SanAntonio.jpg" alt="Butterfly arrives, San Antonio Opera"/>
              <figcaption>Butterfly arrives, San Antonio Opera</figcaption>
            </ImageRight>

            <p>The relatives all arrive, and amongst much wittering, it becomes clear there is some serious friction. Pinkerton delivers yet more crass lines about how brief he is expecting the marriage to be, whilst Butterfly professes to her family how deeply she is in love. Just before the wedding, Butterfly tells Pinkerton that she has converted to Christianity for him and shows him some of her most important possessions, including a box whose contents she will not reveal. Goro tells him it contains the dagger sent to her father from the Mikado to commit seppuku (a rather nasty form of suicide meant to maintain one’s honour).</p>

            <p>The wedding begins and ends expeditiously, and Sharpless, offering one final warning to Pinkerton about Butterfly’s love, leaves the party. The celebrations don’t last long before Butterfly’s uncle, the Bonze, storms in and starts hurling insults. He has learned of her conversion and curses her for it. Pinkerton tries to protect her, and in the process, all the rest of the relatives turn on her, leaving with few good words said.</p>

            <p>We now move into epic love duet mode with “Bimba, Bimba, non piangere”, which takes us to the interval. Pinkerton consoles Butterfly, and the two get entirely mushy for several minutes. He describes her as a butterfly, and she worries because she has heard they pin butterfly’s wings to tables in the West. He dispels her fears, and the act closes as they leave to consummate their marriage...</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-104"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act II - Running Time: 50 mins</SynopsisAct>

            <p>3 Years have passed, and Pinkerton is long gone. Butterfly is running desperately short of money and, believing Pinkerton will return, as he said he would, refuses to marry again. She sings the very famous “Un Bel Di Vedremo”, her dream of how Pinkerton will return.</p>

            <VideoPlayer src="https://www.youtube.com/embed/1woH96ROG-c?autoplay=1" id="1woH96ROG-c" title="Renata Tebaldi as Butterfly"/>

            <p>Sharpless and Goro arrive. Sharpless wishes to read Butterfly a letter from Pinkerton but has no luck, as Goro is in the process of urging her to marry Prince Yamadori, a very wealthy man with multiple wives. Butterfly quickly rejects him. She is still married, she says, under American law, if not Japanese.</p>

            <ImageRight maxwidth="240px">
              <StaticImage src="../../images/operas/butterfly/Butterfly_Sorrow_HoustonGrandOpera.jpg" alt="Ana María Martínez as Butterfly, Houston Grand Opera"/>
              <figcaption>Ana María Martínez as Butterfly, Houston Grand Opera</figcaption>
            </ImageRight>

            <p>Offended, Yamadori and Goro leave. Sharpless returns to the letter, in which Pinkerton tries to prepare Butterfly for his return, including the fact that he has remarried. Butterfly gets overexcited at the idea of his return. Sharpless tries to convince her of the reality of the situation in as gentle a manner as possible. She gets upset and brings out her blond-haired, blue-eyed son.</p>

            <p>This comes as a bit of a shock to Sharpless, and he asks if Pinkerton knows of his son. He doesn’t, Butterfly says, and she wants to send him a letter. Sharpless, dejected, promises to do so and leaves. Suzuki appears dragging Goro, who has been hiding. Goro shouts abuse at Butterfly, calling her son a bastard (in the old-fashioned sense of the word). She almost stabs Goro with her dagger, but he flees.</p>

            <p>A cannon shot is heard. A ship has entered the harbour. Butterfly looks down and sees it is the Abraham Lincoln, Pinkerton’s ship. Cue a lot of flower throwing and general preparation for his arrival. Suzuki, Butterfly and her son settle down for a long night waiting, accompanied by the beautiful Coro a bocca chiusa, or “Humming Chorus”.</p>


            <AudioSet audiosrc="/audio/butterfly/HummingChorus.mp3" desc="The Humming Chorus, Orchestra and Chorus of La Scala, 1955,
                  conducted by Herbert von Karajan"/>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-105"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act III - Running Time: 35 mins</SynopsisAct>

            <p>The night has passed, and Suzuki and the child are asleep. Butterfly remains awake, waiting in silence. In the background, we hear sailors, and the sun finally rises. Butterfly puts her son to bed and falls asleep herself.</p>

            <ImageRight>
              <StaticImage src="../../images/operas/butterfly/Butterfly_Kate_CincinnatiOpera.jpg" alt="Kate Pinkerton arrives, Cincinnati Opera"/>
              <figcaption>Kate Pinkerton arrives, Cincinnati Opera</figcaption>
            </ImageRight>

            <p>Pinkerton and Sharpless arrive with Kate, Pinkerton’s American wife. Suzuki meets them, and upon seeing Pinkerton’s new wife in the garden, she collapses in shock. Pinkerton is his usual insensitive self, but Sharpless tells Suzuki that whilst they can do nothing for Butterfly, Kate would like to adopt the child. Suzuki goes to speak with Kate, and Pinkerton selfishly sings of his own distress, admitting he is too much of a coward to face Butterfly.</p>

            <p>He flees, but Kate stays to reassure Suzuki that she will care for the child as her own. Butterfly awakens and calls for Suzuki. She sees her maid crying and asks why but then notices Sharpless and the mystery woman in the garden. She realises Pinkerton is not coming back. She agrees to hand over the child if and only if Pinkerton returns in half an hour to pick him up himself.</p>

            <p>Butterfly sends Suzuki away, prays and prepares to take her own life with her father’s blade. Her son enters, and Butterfly, now more composed, hugs him close and tells him not to be sad at his mother abandoning him. She blindfolds him and gives him a little American flag to wave as his father returns.</p>

            <p>She takes the knife and stabs herself. As she falls dead, the cries of Pinkerton are heard offstage... </p>

            <p><strong>Butterfly! Butterfly!</strong></p>


          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-106"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Where in the World"/>

            <p><em>Madama Butterfly</em> takes place entirely in a traditional house overlooking the bay of Nagasaki. At the beginning of the 20th century, Nagasaki (marked in blue) was an industrial city with huge docklands, a major access point for the West. Today it is sadly best known as the site of a nuclear bombing at the end of the Second World War.</p>

            <Header title="History"/>

            <p>Puccini got the idea for <em>Madama Butterfly</em> in 1900. He was in London, preparing for the British premiere of his opera <Link to="/operas/tosca">Tosca</Link>, and saw a one-act play called <em>Madame Butterfly</em> written by the well-known American playwright David Belasco. (The Belasco Theater on 44th Street in New York City has been around for more than 100 years.) That play was, in turn, based on a magazine article by Philadelphia lawyer John Luther Long, who claimed that his sister related the story to him based on personal experience.</p>

            <ImageRight>
              <StaticImage src="../../images/operas/butterfly/Butterfly_Long_Cover.jpg" alt="John Luther Long's Book"/>
              <figcaption>John Luther Long's Book</figcaption>
            </ImageRight>

            <p> Puccini was sufficiently moved by Belasco's drama that upon his return to Italy he immediately got to work on the opera with his librettists Luigi Illica and Giuseppe Giacosa (both of whom he had worked with on <Link to="/operas/boheme">La bohème</Link> and <Link to="/operas/tosca">Tosca</Link>). The wife of Japan's ambassador to Italy assisted Puccini by familiarising him with native Japanese songs.</p>

            <p><em>Madama Butterfly</em> premiered at La Scala in Milan, Italy, on the 17th of February 1904, and to say that it was poorly received would be charitable. Almost from the moment the curtain rose, it was hissed, jeered and booed in a manner that probably wouldn’t be tolerated in most prominent opera houses today. (La Scala's playbill history of the opera cites "reliable witnesses" who blame the opening night fiasco on a prearranged claque hostile to Puccini.) After that performance, the composer withdrew the score, modified it (mainly by dividing the lengthy second act into two acts), and next presented the work 60 miles to the east in Brescia on the 28th of May. It was a rousing success and has since been viewed generally as a masterpiece.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-107"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Fun Facts"/>

            <FunFactsHeader>Popular?</FunFactsHeader>

            <p>From 2008 to 2013 <em>Madama Butterfly</em> was the 6th Most Performed Opera in the world according
                to <a href="http://www.operabase.com/top.cgi?lang=en&break=0&show=opera&no=20&nat=">Operabase</a>. Indeed despite the failure of its opening, it's been pretty consistently popular
                since its composition.</p>

            <FunFactsHeader>Names</FunFactsHeader>

            <p>Two interesting notes on character names:</p>

            <p>Pinkerton’s forenames are the subject of some confusion. At the premieres in Milan and Brescia, he is
                introduced as “Sir Francis Blummy Pinkerton,” but by the third revision of the libretto in 1906, he
                is presented as “B.F. Pinkerton.” The 1907 Ricordi score, considered authoritative, lists the role
                as “F.B. Pinkerton”, but during the wedding ceremony, the Commissario refers to the Lieutenant as
                “Benjamin Franklin Pinkerton.” </p>

            <p>As for Pinkerton’s child with Butterfly, she introduces him to Sharpless as Dolore, the Italian word for pain, sorrow, grief. Butterfly says that when his father returns, his name will be Gioia or Joy. The English translation of the libretto refers to the child as Sorrow, which is used in many playbills today; however, some English productions refer to the child as Trouble, which is the name used in Long’s original story.</p>

            <FunFactsHeader>Revisions</FunFactsHeader>

            <p>Puccini revised the libretto for Madama Butterfly several times between 1904 and 1907. One of the biggest changes, made after the Milan premiere, was dividing the second act into two parts, inserting an intermission during Butterfly’s all-night vigil awaiting Pinkerton’s return. Some productions today omit this interval and play Act Two straight through as Puccini originally intended. </p>

            <p> Attempts were also made to soften Pinkerton’s character, including removing some somewhat offensive language from him. Lines cut from the original include his reaction to the names of Butterfly’s servants, which are naturalistic such as Morning Breeze. He mocks them as jokes and says he will instead call them musi, or animal snouts. “First snout, second and third snout.” Despite Puccini’s efforts, Pinkerton is still generally insensitive in the final score. The aria “Addio fiorito asil,” only included in the later revisions, has Pinkerton say goodbye to the flowering refuge of love and joy he shared with Butterfly. He adds, in typical fashion, that he can’t stand its bleakness and squalor, and will flee because he is cowardly and weak.
            </p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-108"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <FunFactsHeader>Inspiration</FunFactsHeader>

            <p>Orientalism, and, Exoticism in general, was highly popular from the mid 19th century onwards, but few knew much about real Asia. While Puccini’s drama may not always accurately depict Japanese culture at the beginning of the 20th century, it is harder to criticise the verisimilitude of the musical score. Puccini incorporated many traditional Japanese songs into Madama Butterfly. One possible source for these tunes is the Austrian Rudolf Dittrich, who taught in Japan in the late 1800s and published piano scores of popular Japanese songs after returning to Europe.</p>

            <p>Below are two music clips that demonstrate the similarities: the first, an excerpt from Jizuki-Uta, a Japanese work song, from the Dittrich arrangement; the second, an excerpt of the soprano Maria Callas singing Butterfly’s aria Che tua madre from 1955.</p>

            <AudioSet audiosrc="/audio/butterfly/JizukiUtaClip.mp3" desc="Jizuki-Uta"/>

            <AudioSet audiosrc="/audio/butterfly/CheTuaClip.mp3" desc="Callas singing Che tua madre"/>

            <FunFactsHeader>Met Premiere</FunFactsHeader>

            <p>The premiere of <em>Madama Butterfly</em> at the Metropolitan Opera came on 11 February 1907, and Puccini oversaw the production. (Belasco also attended rehearsals.) The cast from that night included Geraldine Farrar as Cio-Cio-San, Enrico Caruso as Pinkerton, Louise Homer as Suzuki and Antonio Scotti as Sharpless. Astoundingly it is possible to get some idea of what the audience heard that night as recordings with that same cast were made, several from 1907 to 1909, beginning with Butterfly's entrance, recorded just two days after the Met premiere.
            </p>

            <AudioSet audiosrc="/audio/butterfly/BUTTER2.mp3" desc="“O quanti occhi fisi,” from the end of Act 1, recorded 10 March 1908, sung by Caruso
                    and Farrar"/>

            <p>Puccini was not completely satisfied with the Met premiere, writing that it "lacked the poetry [he] put into it." Farrar was "not what she ought to have been" and Caruso, although "his voice is magnificent," was "lazy" and "too pleased with himself.</p>

            <FunFactsHeader>Miss Saigon</FunFactsHeader>

            <ImageCenter>
              <StaticImage src="../../images/operas/butterfly/MissSaigon_Logo.jpg" alt="Miss Saigon Poster"/>
              <figcaption>Miss Saigon</figcaption>
            </ImageCenter>

            <p>Claude-Michel Schönberg and Alain Boublil have shown themselves to be particular fans of this work. Their musical <em>Miss Saigon</em> is directly based on it and “Bring Him Home” from <em>Les Miserables</em> sounds an awful lot like the “Humming Chorus”...</p>

            <Header title="Bibliography"/>

            <Bibliography content={bibliography}/>

        </MainBodySection>
    </Layout>
  )
}

export default ButterflyPage
